import { WidgetProps } from '@wix/cashier-express-checkout-widget/dist/src/types/WidgetProps';
import { CreateControllerFn } from '@wix/yoshi-flow-editor';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}) => {
  return {
    async pageReady() {
      controllerConfig.setProps({
        isBlocksEditor:
          flowAPI.environment.isEditor &&
          !flowAPI.environment.isEditorX &&
          !flowAPI.environment.isClassicEditor,
      });
    },
    exports: () => {
      return {
        setWidgetProps: (widgetProps: WidgetProps) => {
          try {
            controllerConfig.setProps({ widgetProps });
          } catch (e) {
            console.error(e);
            flowAPI.reportError(e as Error);
          }
        },
      };
    },
  };
};

export default createController;
