
  import userController from '/home/builduser/work/65272e1ccdb126e5/packages/express-checkout-widget-ooi/src/components/ExpressCheckoutWidget/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/controllerWrapper.js';

  
const wrapController = null;


  
var createHttpClient = null;

  
var initI18n = null;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://244597332e474a0cae62fe5bcd78439f@sentry.wixpress.com/2364',
    id: '244597332e474a0cae62fe5bcd78439f',
    projectName: 'express-checkout-widget-ooi',
    teamName: 'payments',
    errorMonitor: true,
  };

  var experimentsConfig = null;

  var translationsConfig = {"enabled":false,"icuEnabled":true};

  var biConfig = null;

  var defaultTranslations = null;

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/65272e1ccdb126e5/packages/express-checkout-widget-ooi/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "express-checkout-widget-ooi",
    componentName: "ExpressCheckoutWidget",
    appDefinitionId: "47e245ca-1a42-4d6a-a69a-c125bc839b40",
    componentId: "54fb025c-61dc-4286-87c7-0ac416c58744",
    isUsingAppSettings: false,
    projectName: "express-checkout-widget-ooi",
    defaultTranslations,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/65272e1ccdb126e5/packages/express-checkout-widget-ooi/src/components/ExpressCheckoutWidget/controller.ts",
  };

  export const controller = _controller
  export default controller;
